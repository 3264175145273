import React, { useState } from "react";
import { Form, Alert, Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import HTMLReactParser from "html-react-parser";
import "./Email.css";

function Email() {
  const [emailData, setEmailData] = useState({
    recipients: [],
    subject: "",
    htmlBody: "", // Corrected field name
    cc: [],
    replyTo: "",
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [showHTMLPreview, setShowHTMLPreview] = useState(true);

  const handleAlertClose = () => setShowAlert(false);

  const handleRecipientsChange = (e) => {
    const { value } = e.target;
    setEmailData({
      ...emailData,
      recipients: value.split(",").map((item) => item.trim()),
    });
  };

  const handleCCChange = (e) => {
    const { value } = e.target;
    setEmailData({
      ...emailData,
      cc: value.split(",").map((item) => item.trim()),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const response = await axios.post(
        "https://mail.suhailroushan.in/api/email/send",
        {
          recipients: emailData.recipients,
          subject: emailData.subject,
          htmlBody: emailData.htmlBody,
          cc: emailData.cc,
          replyTo: emailData.replyTo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setAlertVariant("success");
        setAlertMessage("Email sent successfully!");
        setShowAlert(true);
        setEmailData({
          recipients: [],
          subject: "",
          htmlBody: "", // Clear the htmlBody field
          cc: [],
          replyTo: "",
        });
      }
    } catch (error) {
      if (error.response) {
        setAlertVariant("danger");
        setAlertMessage(`Failed to send email: ${error.response.data.message}`);
      } else {
        setAlertVariant("danger");
        setAlertMessage("An error occurred while sending the email.");
      }
      setShowAlert(true);
      console.error("Error sending email:", error);
    }

    setIsSending(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const togglePreview = () => {
    setShowHTMLPreview(!showHTMLPreview);
  };

  return (
    <div className="email-container">
      <Row>
        <Col md={6}>
          <Card className="neumorphism-card">
            <Card.Body className="form">
              <h2 className="email-heading">Compose Email 📩</h2>
              {showAlert && (
                <Alert
                  variant={alertVariant}
                  onClose={handleAlertClose}
                  dismissible
                  className="email-alert"
                >
                  {alertMessage}
                </Alert>
              )}
              <Form onSubmit={handleSubmit} className="email-form">
                <Form.Group controlId="recipients">
                  <Form.Label>Recipients</Form.Label>
                  <Form.Control
                    type="text"
                    name="recipients"
                    value={emailData.recipients.join(",")}
                    onChange={handleRecipientsChange}
                    className="email-input"
                  />
                </Form.Group>

                <Form.Group controlId="subject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    name="subject"
                    value={emailData.subject}
                    onChange={handleInputChange}
                    className="email-input"
                  />
                </Form.Group>

                <Form.Group controlId="htmlBody">
                  <Form.Label>Body</Form.Label>
                  <Button
                    type="button"
                    variant="primary"
                    className="email-btn"
                    onClick={togglePreview}
                  >
                    {showHTMLPreview
                      ? "Switch to Text Preview"
                      : "Switch to HTML Preview"}
                  </Button>
                  <br></br>

                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="htmlBody"
                    value={emailData.htmlBody}
                    onChange={handleInputChange}
                    className="email-input"
                  />
                </Form.Group>

                <Form.Group controlId="cc">
                  <Form.Label>CC</Form.Label>
                  <Form.Control
                    type="text"
                    name="cc"
                    value={emailData.cc.join(",")}
                    onChange={handleCCChange}
                    className="email-input"
                  />
                </Form.Group>

                <Form.Group controlId="replyTo">
                  <Form.Label>Reply To</Form.Label>
                  <Form.Control
                    type="text"
                    name="replyTo"
                    value={emailData.replyTo}
                    onChange={handleInputChange}
                    className="email-input"
                  />
                </Form.Group>

                <br />
                <Button
                  type="submit"
                  variant="primary"
                  className="button"
                  onClick={handleSubmit}
                  disabled={isSending}
                >
                  {isSending ? "Sending..." : "Send"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col className="custom-border" md={6}>
          <center>
            <h4>{showHTMLPreview ? "HTML Preview" : "Text Preview"}</h4>
          </center>
          <Card className="neumorphism-card">
            <Card.Body className="form">
              {showHTMLPreview ? (
                HTMLReactParser(emailData.htmlBody)
              ) : (
                <pre>{emailData.htmlBody}</pre>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}></Col>
        <Col md={6}>
          <center></center>
        </Col>
      </Row>
    </div>
  );
}

export default Email;
