import React from "react";
import Email from "./Email/Email";

function App() {
  return (
    <>
      <Email></Email>
    </>
  );
}

export default App;
